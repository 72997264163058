import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Container, Typography, Fab } from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import SearchBar from './SearchBar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './TourCard.css';
import './LoadingAnimation.css'; // Импорт стилей для анимации загрузки

const TourList = () => {
    const [allTours, setAllTours] = useState([]);
    const [filteredTours, setFilteredTours] = useState([]);
    const [activeTours, setActiveTours] = useState([]);
    const [inactiveTours, setInactiveTours] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    // Функция для загрузки всех туров
    const fetchAllTours = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tours`);
            const data = await response.json();

            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);

            setAllTours(data);
            filterTours(data, {});
        } catch (error) {
            console.error('Ошибка загрузки туров:', error);
        } finally {
            setLoading(false);
        }
    };

    // Функция для фильтрации туров
    const filterTours = (tours, filters) => {
        const { searchTerm = '', startDate, endDate, peopleCount } = filters;

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        const filtered = tours.filter(tour => {
            const matchesSearch = searchTerm
                ? tour.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  tour.region.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  tour.cityStart.toLowerCase().includes(searchTerm.toLowerCase())
                : true;

            const matchesDateRange = startDate && endDate
                ? tour.dates.some(({ dateStart, dateFinish }) => {
                      const start = new Date(dateStart);
                      const end = new Date(dateFinish);
                      return start <= endDate && end >= startDate;
                  })
                : true;

            const matchesPeopleCount = peopleCount
                ? tour.dates.some(({ freeMest }) => parseInt(freeMest, 10) >= peopleCount)
                : true;

            return matchesSearch && matchesDateRange && matchesPeopleCount;
        });

        // Разделение на активные и неактивные туры
        const active = filtered.filter(tour =>
            Array.isArray(tour.dates) &&
            tour.dates.some(({ dateStart, freeMest }) => {
                const startDate = new Date(dateStart);
                return startDate > tomorrow && parseInt(freeMest, 10) > 0;
            })
        );

        const inactive = filtered.filter(tour =>
            !Array.isArray(tour.dates) ||
            !tour.dates.some(({ dateStart, freeMest }) => {
                const startDate = new Date(dateStart);
                return startDate > tomorrow && parseInt(freeMest, 10) > 0;
            })
        );

        setFilteredTours(filtered);
        setActiveTours(active);
        setInactiveTours(inactive);
    };

    // Обработчик поиска
    const handleSearch = (filters) => {
        filterTours(allTours, filters);
    };

    useEffect(() => {
        fetchAllTours();
    }, []);

    // Обработчик прокрутки для кнопки "вверх"
    useEffect(() => {
        const handleScroll = () => {
            setShowScrollToTop(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderTours = (tours, isInactive = false) => (
        tours.map((tour) => (
            <Grid item xs={12} sm={6} md={4} key={tour.turId}>
                <Card
                    component={Link}
                    to={`/tours/${tour.turId}`}
                    className={`card-container ${isInactive ? 'inactive-card' : ''}`}
                >
                    <Slider {...sliderSettings}>
                        {tour.photoGallery.map((photo, index) => (
                            <div key={index}>
                                <img
                                    src={photo || 'https://via.placeholder.com/300'}
                                    alt={`Фото тура ${tour.name}`}
                                    className="card-image"
                                />
                            </div>
                        ))}
                    </Slider>

                    <CardContent className="card-content">
                        <Typography gutterBottom variant="h5">
                            {tour.name}
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'orange' }}>
                            {tour.cost} ₽
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            className="card-description"
                        >
                            {tour.smallDescriptions}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        ))
    );

    return (
        <Container sx={{ marginTop: '30px', paddingBottom: '50px' }}>
            <SearchBar onSearch={handleSearch} />

            {loading ? (
                <div className="loading-container">
                    <div className="double-ring"></div>
                </div>
            ) : (
                <>
                    {activeTours.length > 0 && (
                        <>
                            
                            <Grid container spacing={4}>
                                {renderTours(activeTours)}
                            </Grid>
                        </>
                    )}

                    {inactiveTours.length > 0 && (
                        <>
                            <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '20px' }}>
                                Нет свободных мест
                            </Typography>
                            <Grid container spacing={4}>
                                {renderTours(inactiveTours, true)}
                            </Grid>
                        </>
                    )}

                    {filteredTours.length === 0 && (
                        <Typography sx={{ textAlign: 'center', marginTop: '20px' }}>
                            Туров не найдено
                        </Typography>
                    )}
                </>
            )}

            {showScrollToTop && (
                <Fab
                    size="medium"
                    onClick={handleScrollToTop}
                    sx={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        zIndex: 1000,
                        backgroundColor: 'orange',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#FF8C00',
                        },
                    }}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            )}
        </Container>
    );
};

export default TourList;
